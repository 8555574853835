import "./styles.scss"

import React from "react"
import clsx from "clsx"

const SingleHero = ({ title, subtitle, isTeam }) => {
  return (
    <section className={clsx("single-hero", isTeam && "single-hero--team")}>
      <div className="container-fluid">
        <div className="single-hero__wrapper">
          <h1 className="h2">{title}</h1>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
    </section>
  )
}

export default SingleHero
