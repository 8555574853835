import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SingleHero from "components/SingleHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { SingleContent } from "page_components/competences"

const Competence = ({ pageContext, data, location }) => {
  return (
    <Layout location={location} seo={{ title: pageContext.data.title }}>
      <SingleHero title={pageContext.data.title} />
      <Breadcrumbs
        parent={{ title: "Kompetencje", url: "/kompetencje/" }}
        title={pageContext.data.title}
      />
      <SingleContent
        data={pageContext.data}
        allWpCompetence={data?.allWpCompetence?.nodes}
      />
      <SubpageBack target="/kompetencje/" />
    </Layout>
  )
}

export const query = graphql`
  query ($language: WpLanguageCodeEnum) {
    allWpCompetence(
      sort: { order: ASC, fields: date }
      filter: { language: { code: { eq: $language } } }
    ) {
      nodes {
        title
        slug
        id
      }
    }
  }
`

export default Competence
